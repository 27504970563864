import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "./header"

const TextContainer = styled.div`
  text-align: center;
  color: #eee;
  padding: 8% 0 13%;
`

const BigText = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  padding-bottom: 4%;

  @media (max-width: 1000px) {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 750px) {
    font-size: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const SmallText = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  padding-bottom: 6%;

  @media (max-width: 1000px) {
    font-size: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 750px) {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const Button = styled(Link)`
    background-color: #00477a;
    color: #eee;
    padding: 1rem 2rem;
    font-size: 2rem;
    text-decoration: none;
    border-radius: 4px;
    transition: all .1s ease;

    &:hover {
      background-color: #005999;
      color: white;
    }

    @media (max-width: 1000px) {
      font-size: 1.5rem;
    }

    @media (max-width: 750px) {
      font-size: 1rem;
    }
`

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
`

const BackgroundSection = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hotel image.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.placeholderImage.childImageSharp.fluid}
    >
        <Overlay>
      <Header />
      <TextContainer>
        <BigText>Make the most of&nbsp;your&nbsp;investments</BigText>
        <SmallText>
          A 1031 Exchange lets you defer your taxes&nbsp;on&nbsp;capital&nbsp;gains
        </SmallText>
        <Button to='/contact'>Find out how</Button>
      </TextContainer>
      </Overlay>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
