import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1680px;
  margin: auto;
  padding: 0 2rem 2rem;
`

const LogoText = styled.h1`
  font-size: 4rem;
  font-weight: 400;
  margin: 0;
  padding-top: 10%;

  @media (max-width: 1000px) {
    font-size: 3rem;
  }
`

const LogoMotto = styled.h5`
  font-size: 1.5rem;
  font-weight: 400;
  float: right;
  margin: -2% 5% 0 0;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }
`

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  color: #ddd;
  transition: all 0.5s ease;
  margin: 0;

  @media (max-width: 800px) {
    display: block;
    position: fixed;
    float: right;
    width: 60%;
    height: 100%;
    top: 0;
    right: 0;
    text-align: left;
    background-color: #222;
    z-index: 7;
    padding: 2rem 1rem 3rem 2rem;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(${props => (!props.navExpanded ? "100%" : "0")}, 0);
    transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
`

const NavLink = styled.li`
  margin: 0;
  padding: 2rem 1rem 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`

const LogoLink = styled(Link)`
  text-decoration: none;
  color: #fefefe;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #dedede;

  &:hover {
    color: #fff;
  }
`

const ContactLink = styled(Link)`
  text-decoration: none;
  color: white;
  border: 2px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 800px) {
    border: none;
    padding: none;

    &:hover {
      background-color: inherit;
      color: #ccc;
    }
  }
`

const HoverLink = styled.li`
  margin: 0;
  padding: 2rem 1rem 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`

const HoverWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover ${HoverLink} {
    color: #fff;
  }
`

const HoverMenu = styled.div`
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;

  ${HoverWrapper}:hover & {
    display: block;
  }

  @media (max-width: 800px) {
    display: block;
    position: inherit;
    z-index: inherit;
    background-color: inherit;
    box-shadow: inherit;
  }
`

const HoverMenuLink = styled(Link)`
  color: white;
  padding: 1rem 2rem 1rem 1rem;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 800px) {
    padding: 1rem 2rem;

    &:hover {
      background-color: inherit;
    }
  }
`

const MobileNavButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;

  @media (max-width: 800px) {
    display: block;
  }
`

const MobileNavExit = styled.button`
  display: none;
  background-color: transparent;
  border: none;

  @media (max-width: 800px) {
    display: block;
    float: right;
    padding-right: 1.5rem;
  }
`

const Header = ({ color }) => {
  var [navExpanded, setNavExpanded] = useState(false)

  return (
    <header style={{ backgroundColor: color }}>
      <HeaderWrapper>
        <LogoLink to="/">
          <LogoText>1031&nbsp;Agency</LogoText>
          <LogoMotto>Invest&nbsp;Smarter</LogoMotto>
        </LogoLink>
        <nav>
          <MobileNavButton onClick={() => setNavExpanded(true)}>
            <img src={require("../images/menu.svg")} alt="menu button" />
          </MobileNavButton>
          <NavLinks navExpanded={navExpanded}>
            <MobileNavExit
              navExpanded={navExpanded}
              onClick={() => setNavExpanded(false)}
            >
              <img
                src={require("../images/exit.svg")}
                alt="exit button"
                style={{ width: "32px" }}
              />
            </MobileNavExit>
            <NavLink>
              <StyledLink to="/">Home</StyledLink>
            </NavLink>
            {/* <NavLink>
            <StyledLink to="/about">Our Team</StyledLink>
          </NavLink> */}
            {/* <HoverWrapper>
            <HoverLink>
              Resources{" "}
              <span style={{ fontSize: ".5rem", verticalAlign: "middle" }}>
                ▼
              </span>
            </HoverLink>
            <HoverMenu>
              <HoverMenuLink to="/forms">Forms</HoverMenuLink>
              <HoverMenuLink to="/faq">FAQs</HoverMenuLink>
            </HoverMenu>
          </HoverWrapper> */}
            <NavLink>
              <StyledLink to="/forms">Forms</StyledLink>
            </NavLink>
            <NavLink>
              <StyledLink to="/faq">FAQs</StyledLink>
            </NavLink>
            <NavLink>
              <ContactLink to="/contact" color={color}>
                Contact Us
              </ContactLink>
            </NavLink>
          </NavLinks>
        </nav>
      </HeaderWrapper>
    </header>
  )
}

export default Header
