import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors } from '../styles';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'copyright questions phone';
  grid-gap: 1.5em;
  font-size: 0.8rem;
  background-color: ${colors.primaryDark};
  color: #ccc;
  text-align: center;
  white-space: nowrap;
  padding: 1rem;

  & > * {
    margin: 0;
  }

  @media (max-width: 475px) {
    font-size: 1.1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'questions questions'
      'copyright phone';
  }
`;

const PhoneLink = styled.a`
  grid-area: phone;
  text-decoration: none;
  color: unset;
  @media (hover: hover) {
    &:hover {
      color: #aaa;
    }
  }
`;

const Copyright = styled.p`
  grid-area: copyright;
`;

const Questions = styled(Link)`
  grid-area: questions;
  color: unset;
`;

const Footer = () => {
  return (
    <footer>
      <Wrapper>
        <Copyright>© {new Date().getFullYear()}, 1031 Agency</Copyright>
        <Questions to="/contact">Questions? We're here to help!</Questions>
        <PhoneLink href="tel:7277875290">(727) 787-5290</PhoneLink>
      </Wrapper>
    </footer>
  );
};

export default Footer;
